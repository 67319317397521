import { lazy } from 'react';
import { Navigate } from 'react-router';

import Loadable from 'hoc/Loadable';

import MinimalLayout from 'layout/MinimalLayout';

const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = [
    {
        path: '/',
        element: <MinimalLayout />,
        children: [
            {
                path: 'login',
                element: <AuthLogin />,
            },
            { path: '*', element: <Navigate to="/login" replace /> },
            { path: '', element: <Navigate to="/login" replace /> },
        ],
    },
];

export default LoginRoutes;
