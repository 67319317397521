import { Children, cloneElement, isValidElement, useCallback, useMemo, useRef, useState } from 'react';

import { GenericDetails } from 'components/Input/Autocomplete';

import { Chip, Typography } from '@mui/material';

import { PopperBox } from './components/PopperBox';

interface ChipToolbarProps {
    label: string;
    value?: GenericDetails;
    onClear?: () => void;
    children?: React.ReactNode;
    dataLocator?: string;
}

const ChipToolbar = ({ label, value, onClear, children, dataLocator }: ChipToolbarProps) => {
    const refChip = useRef<HTMLDivElement>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const isUserInteraction = value?.name && (value?.id || value?.value);

    const getChipLabel = useCallback(() => {
        if (isUserInteraction) {
            return (
                <>
                    <Typography letterSpacing="0.16px" fontSize="13px" fontWeight="400" lineHeight="18px" component="span">
                        {label}:&nbsp;
                    </Typography>
                    <Typography
                        letterSpacing="0.16px"
                        fontSize="13px"
                        fontWeight="400"
                        lineHeight="18px"
                        component="span"
                        color={anchorEl ? 'white' : 'text.secondary'}
                    >
                        {value?.name}
                    </Typography>
                </>
            );
        }

        return label;
    }, [label, value?.name, isUserInteraction, anchorEl]);

    const handleDelete = () => {
        onClear && onClear();
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);

        // Remove focus-visible class after closing the popper
        const focusVisibleClassName = 'Mui-focusVisible';
        setTimeout(() => {
            refChip.current?.classList.remove(focusVisibleClassName);
        }, 100);
    };

    const childrenWithProps = Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement<any>(child, { handleClose });
        }

        return child;
    });

    const variant = useMemo((): 'filled' | 'outlined' | undefined => {
        if (anchorEl) {
            return 'filled';
        }

        if (isUserInteraction) {
            return 'outlined';
        }
    }, [anchorEl, isUserInteraction]);

    return (
        <>
            <Chip
                ref={refChip}
                data-locator={dataLocator}
                variant={variant}
                color={anchorEl ? 'primary' : 'default'}
                onClick={handleClick}
                label={getChipLabel()}
                onDelete={isUserInteraction ? handleDelete : undefined}
                sx={{
                    borderRadius: '100px',
                    maxWidth: '400px',
                }}
            />
            <PopperBox anchorEl={anchorEl} handleClose={handleClose}>
                {childrenWithProps}
            </PopperBox>
        </>
    );
};

export { ChipToolbar };
