import { Auth } from 'aws-amplify';

import config from 'config';

interface RequestOptions extends RequestInit {
    params?: Record<string, string>;
}

interface ErrorResponse {
    status: number;
    data: unknown;
}

export const request = async (input: RequestInfo | URL, options?: RequestOptions): Promise<any> => {
    try {
        const token = await Auth.currentSession().then((session) => {
            return session.getIdToken().getJwtToken();
        });

        const { params, ...fetchOptions } = options ?? {};

        const headers = {
            Authorization: `Bearer ${token}`,
            'x-api-key': config.API_KEY,
            version: '1',
            ...fetchOptions?.headers,
        };

        let url: string | undefined;
        let paramsString = '';

        if (params) {
            paramsString = `?${new URLSearchParams(params)}`;
        }

        if (typeof input === 'string') {
            url = `${config.API_URL}${input}${paramsString}`;
        }

        const response = await fetch(url ?? input, { ...fetchOptions, headers });

        if (response.ok) {
            return response.json();
        } else {
            await handleErrorResponse(response);
        }
    } catch (error) {
        const err = error as ErrorResponse;
        return Promise.reject(err);
    }
};

const handleErrorResponse = async (response: Response): Promise<ErrorResponse> => {
    const errorData = await response.json().catch(() => response);
    throw { status: response.status, data: errorData };
};
