import { useCallback, useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';

import { GenericDetails } from 'components/Input/Autocomplete';
import { sortByPartialMatch } from 'components/Input/Autocomplete/utils';

interface UseFilterInputProps<T> {
    options?: T[];
    selectedItem?: T | null;
    setSelectedItem: (item: T | null) => void;
    onClear?: () => void;
    handleClose?: () => void;
}

const DEBOUNCE_DELAY = 700;

const useDebouncedFilterInput = <T extends GenericDetails>({
    options = [],
    selectedItem,
    setSelectedItem,
    onClear,
    handleClose,
}: UseFilterInputProps<T>) => {
    const [optionsList, setOptionsList] = useState<T[]>(options);
    const [localInputValue, setLocalInputValue] = useState<string>(selectedItem?.name || '');
    const [localSelectedItem, setLocalSelectedItem] = useState<T | null | undefined>(selectedItem);
    const isFieldEmpty = !localInputValue;

    useEffect(() => {
        setOptionsList(options);
    }, [options]);

    const debounceFetch = (value: string) => {
        if (!value) return undefined;
        // to avoid useless refetch
        if (localSelectedItem?.name === value) return undefined;
        // do not start search if value less than 2
        if (value.length < 2) return undefined;

        setLocalInputValue(value);
    };

    const debouncedInputChange = useCallback(
        debounce((val: string) => debounceFetch(val), DEBOUNCE_DELAY),
        []
    );

    const handleChange = useCallback(
        (_event: React.SyntheticEvent, newItem: T | null) => {
            if (newItem && newItem?.name) {
                setLocalInputValue(newItem?.name);
                setSelectedItem(newItem);
                handleClose && handleClose();
            }
        },
        [handleClose, setSelectedItem]
    );

    const sortedOptions = useMemo((): T[] => {
        if (localInputValue && optionsList) {
            return sortByPartialMatch(optionsList, localInputValue, 'name');
        } else {
            return optionsList;
        }
    }, [optionsList, localInputValue]);

    const handleClear = useCallback(() => {
        setLocalInputValue('');
        setSelectedItem(null);
        onClear && onClear();
    }, [setSelectedItem, onClear]);

    const handleApply = useCallback(() => {
        setSelectedItem({ name: localInputValue, value: localInputValue } as T);
        handleClose && handleClose();
    }, [localInputValue, setSelectedItem, handleClose]);

    return {
        localInputValue,
        localSelectedItem,
        handleChange,
        debouncedInputChange,
        sortedOptions,
        handleClear,
        handleApply,
        setLocalSelectedItem,
        setLocalInputValue,
        setOptionsList,
        isFieldEmpty,
        maxItems: sortedOptions.length,
    };
};

export { useDebouncedFilterInput };
