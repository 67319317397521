import { alpha, createTheme, PaletteMode } from '@mui/material';

import { presetColors } from './presetColors';

const Palette = (mode: PaletteMode) => {
    return createTheme({
        palette: {
            mode,
            common: {
                black: presetColors.grey[900],
                white: presetColors.grey[0],
            },
            ...presetColors,
            primary: {
                // custom colors from the design system
                main: '#6481FF',
                light: '#B693FE',
                dark: '#783CFF',
                contrastText: presetColors.grey[0],
            },
            secondary: {
                // custom colors from the design system
                main: '#9564FF',
                light: '#FFD19D',
                dark: '#FFA01E',
                contrastText: presetColors.grey[0],
            },
            error: {
                // custom colors from the design system
                main: '#EC5152',
                light: '#E27273',
                dark: '#F14038',
                contrastText: presetColors.grey[0],
            },
            warning: {
                // custom colors from the design system
                main: '#F57002',
                light: '#FF9800',
                dark: '#ED5400',
                contrastText: presetColors.grey[0],
            },
            info: {
                // custom colors from the design system
                main: '#9564FF',
                light: '#B693FE',
                dark: '#783CFF',
                contrastText: presetColors.grey[0],
            },
            success: {
                // custom colors from the design system
                main: '#009B72',
                light: '#29AB84',
                dark: '#008E66',
                contrastText: presetColors.grey[0],
            },
            text: {
                primary: alpha(presetColors.grey[800], 0.87),
                secondary: alpha(presetColors.grey[800], 0.6),
                disabled: alpha(presetColors.grey[800], 0.38),
            },
            action: {
                active: alpha(presetColors.grey[800], 0.56),
                hover: alpha(presetColors.grey[800], 0.04),
                selected: alpha(presetColors.grey[800], 0.08),
                disabled: alpha(presetColors.grey[800], 0.38),
                disabledBackground: alpha(presetColors.grey[800], 0.12),
                focus: alpha(presetColors.grey[800], 0.12),
            },
            divider: alpha(presetColors.grey[800], 0.12),
            background: {
                paper: presetColors.grey[0],
                // custom colors from the design system
                default: '#F7F7F8',
            },
            alert: {
                // custom colors from the design system
                infoContent: '#392080',
                infoFill: '#F6F0FF',
            },
        },
    });
};

export default Palette;
