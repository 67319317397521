import { FONT_SIZE_BASE, FONT_WEIGHT_BOLD, FONT_WEIGHT_LIGHT, FONT_WEIGHT_MEDIUM, FONT_WEIGHT_REGULAR } from './fonts';

const Typography = (fontFamily: string) => ({
    htmlFontSize: FONT_SIZE_BASE,
    fontFamily,
    fontWeightLight: FONT_WEIGHT_LIGHT,
    fontWeightRegular: FONT_WEIGHT_REGULAR,
    fontWeightMedium: FONT_WEIGHT_MEDIUM,
    fontWeightBold: FONT_WEIGHT_BOLD,
    h1: {
        fontWeight: FONT_WEIGHT_LIGHT,
        fontSize: '96px',
        lineHeight: '112px',
        letterSpacing: '-1.5px',
    },
    h2: {
        fontWeight: FONT_WEIGHT_LIGHT,
        fontSize: '60px',
        lineHeight: '72px',
        letterSpacing: '-0.5px',
    },
    h3: {
        fontWeight: FONT_WEIGHT_REGULAR,
        fontSize: '48px',
        lineHeight: '56px',
    },
    h4: {
        fontWeight: FONT_WEIGHT_REGULAR,
        fontSize: '34px',
        lineHeight: '42px',
        letterSpacing: '0.25px',
    },
    h5: {
        fontWeight: FONT_WEIGHT_REGULAR,
        fontSize: '24px',
        lineHeight: '32px',
    },
    h6: {
        fontWeight: FONT_WEIGHT_MEDIUM,
        fontSize: '20px',
        lineHeight: '32px',
        letterSpacing: '0.15px',
    },
    body1: {
        fontWeight: FONT_WEIGHT_REGULAR,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
    },
    body2: {
        fontWeight: FONT_WEIGHT_REGULAR,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.17px',
    },
    subtitle1: {
        fontSize: '16px',
        fontWeight: FONT_WEIGHT_REGULAR,
        lineHeight: '28px',
        letterSpacing: '0.15px',
    },
    subtitle2: {
        fontSize: '14px',
        fontWeight: FONT_WEIGHT_MEDIUM,
        lineHeight: '22px',
        letterSpacing: '0.1px',
    },
    caption: {
        fontWeight: FONT_WEIGHT_REGULAR,
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.4px',
    },
    overline: {
        fontSize: '12px',
        fontWeight: FONT_WEIGHT_REGULAR,
        lineHeight: '32px',
        letterSpacing: '1px',
        textTransform: 'uppercase',
    },
    button: {
        textTransform: 'capitalize',
    },
});

export default Typography;
