import { memo } from 'react';

import { GenericDetails } from 'components/Input/Autocomplete';
import { DatePicker } from 'components/Toolbar/components/Fields/DatePicker';
import { SearchField } from 'components/Toolbar/components/Fields/SearchField';
import { SearchFilter } from 'components/Toolbar/components/Fields/SearchFilter';
import { SingleSelect } from 'components/Toolbar/components/Fields/SingleSelect';
import { DEFAULT_OPTION_ID, DEFAULT_OPTION_NAME } from 'components/Toolbar/constants';
import { Field } from 'components/Toolbar/types';
import { generateValues, normalizeFieldOptions } from 'components/Toolbar/utils';

import { FormControlLabel, Switch } from '@mui/material';

interface FieldRendererProps {
    field: Field;
    formData: Record<string, any>;
    setFieldValue: (name: string, value: any) => void;
}

const FieldRenderer = memo(({ field, formData, setFieldValue, ...rest }: FieldRendererProps) => {
    const commonProps = {
        key: `${field.name}_${field.label}`,
        name: field.name,
        label: field.label,
        placeholder: field.placeholder,
        ...rest,
    };

    const handleChangeSwitcher = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(field.name, generateValues({ name: String(e.target.checked), id: e.target.checked }));
    };

    const handleChangeAutocomplete = (newItem: GenericDetails | null) => {
        if (!newItem) {
            setFieldValue(field.name, generateValues());
            return;
        }
        setFieldValue(field.name, generateValues(newItem));
    };

    const handleDatePickerChange = (date: string) => {
        setFieldValue(field.name, generateValues({ id: date, name: date }));
    };

    switch (field.type) {
        case 'switch':
            return (
                <FormControlLabel
                    {...commonProps}
                    control={
                        <Switch
                            checked={Boolean(formData[field.name]?.id) || false}
                            value={Boolean(formData[field.name]?.id) || false}
                            onChange={handleChangeSwitcher}
                            name={field.name}
                            data-locator={`${field.name}_switch`}
                        />
                    }
                    componentsProps={{ typography: { variant: 'body2' } }}
                />
            );
        case 'single-select':
            return (
                <SingleSelect
                    {...commonProps}
                    selectedItem={formData[field.name]}
                    options={normalizeFieldOptions(
                        field.options,
                        field.optionName || DEFAULT_OPTION_NAME,
                        field.optionId || DEFAULT_OPTION_ID,
                        field.optionValue || DEFAULT_OPTION_NAME
                    )}
                    setSelectedItem={handleChangeAutocomplete}
                    isLoading={field.loading}
                />
            );
        case 'search-filter':
            return (
                <SearchFilter
                    {...commonProps}
                    selectedItem={formData[field.name]}
                    options={[]}
                    setSelectedItem={handleChangeAutocomplete}
                    providerName={field.providerName}
                    optionName={field.optionName || DEFAULT_OPTION_NAME}
                    optionValue={field.optionValue || DEFAULT_OPTION_NAME}
                    optionId={field.optionId || DEFAULT_OPTION_ID}
                    additionalParams={field.additionalParams}
                />
            );
        case 'search':
            return (
                <SearchField
                    {...commonProps}
                    defaultValue={formData[field.name]?.id}
                    onCancel={() => setFieldValue(field.name, generateValues())}
                    onApply={(value) => setFieldValue(field.name, generateValues({ name: value, value }))}
                />
            );
        case 'date-picker':
            return <DatePicker {...commonProps} value={(formData[field.name]?.id as string) || ''} onChange={handleDatePickerChange} />;
        default:
            throw new Error(`Field type ${field.type} is not supported`);
    }
});

export { FieldRenderer };
