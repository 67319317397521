import React from 'react';

import { Alert, AlertColor, Snackbar, SnackbarOrigin } from '@mui/material';
import { AlertPropsVariantOverrides } from '@mui/material/Alert/Alert';
import { OverridableStringUnion } from '@mui/types';

type Props = {
    children: React.ReactNode;
};

type GlobalMessageConfig = {
    message: string;
    severity?: AlertColor;
    filled?: OverridableStringUnion<'standard' | 'filled' | 'outlined', AlertPropsVariantOverrides>;
    autoHideDuration?: number;
    anchorOrigin?: SnackbarOrigin;
};

const defaultConfig: GlobalMessageConfig = {
    message: '',
    severity: 'info',
    filled: 'standard',
    autoHideDuration: 5000,
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
};

const GlobalMessageContext = React.createContext({
    pushGlobalMessage: (_messageData: GlobalMessageConfig) => {},
});

export const useGlobalMessage = () => React.useContext(GlobalMessageContext);

export const GlobalMessageProvider = ({ children }: Props) => {
    const [state, setState] = React.useState<GlobalMessageConfig | null>(null);

    const handleClose = () => setState(null);

    return (
        <GlobalMessageContext.Provider value={{ pushGlobalMessage: setState }}>
            {children}
            <Snackbar
                anchorOrigin={state?.anchorOrigin ?? defaultConfig.anchorOrigin}
                open={Boolean(state)}
                autoHideDuration={state?.autoHideDuration ?? defaultConfig.autoHideDuration}
                onClose={handleClose}
            >
                <Alert
                    variant={state?.filled ?? defaultConfig.filled}
                    onClose={handleClose}
                    severity={state?.severity ?? defaultConfig.severity}
                    sx={{ width: '100%' }}
                >
                    {state?.message}
                </Alert>
            </Snackbar>
        </GlobalMessageContext.Provider>
    );
};
