import React from 'react';

import useAuth from 'hooks/authentication/useAuth';

import { Box, Button, Link, Typography } from '@mui/material';

import { CONFLUENCE_ACCESS_LINK, SLACK_CHANNEL_LINK } from 'constants/links';

import LockIcon from 'ui-elements/icons/IconLock';
import Logo from 'ui-elements/Logo';

function NoPermissions() {
    const { logout } = useAuth();

    const handleLogout = async () => {
        logout();
    };

    return (
        <>
            <Box style={{ padding: '20px 24px', position: 'fixed', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Logo />
                <Button onClick={handleLogout}>
                    <Typography>Logout</Typography>
                </Button>
            </Box>
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100vh',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: 28,
                    maxWidth: 400,
                    margin: '0 auto',
                }}
            >
                <LockIcon />
                <Typography variant="h4" textAlign="center" mb={3}>
                    Access Permission Required
                </Typography>
                <Typography variant="body1" align="center" width={500} mb={3}>
                    To change your permissions, please check&nbsp;
                    <Link href={CONFLUENCE_ACCESS_LINK} target="_blank" rel="noreferrer" sx={{ textDecoration: 'underline' }}>
                        the document
                    </Link>
                    &nbsp;or contact Platform team via&nbsp;
                    <Link href={SLACK_CHANNEL_LINK} target="_blank" rel="noreferrer" sx={{ textDecoration: 'underline' }}>
                        #platform-assistance
                    </Link>
                    &nbsp;Slack channel.
                </Typography>
                <Button
                    component={Link}
                    variant="outlined"
                    size="large"
                    href={SLACK_CHANNEL_LINK}
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                        textTransform: 'uppercase',
                    }}
                >
                    Go to Slack
                </Button>
            </Box>
        </>
    );
}

export default NoPermissions;
