
        import config from './src/config'

        const CONFIG_WELLFUNNEL_STUDIO = {
            WELLFUNNEL_API_URL: config.PREVIEW_API_URL,
            WELLFUNNEL_API_KEY: config.PREVIEW_API_URL,
            PRODUCT_API_ID: 'walking',
            PRODUCT_API_URL: config.PREVIEW_PRODUCT_API_URL,
            PRODUCT_API_KEY: config.PREVIEW_PRODUCT_API_KEY,
            PRODUCT_API_VERSION: '2',
            PROJECT_NAME: 'walkfit',
            //just need string for init preview
            CABINET_URL: ''
        }
        window.wellFunnelConfig = {
            ...CONFIG_WELLFUNNEL_STUDIO
        }
    