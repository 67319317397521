// Define a scoring function to calculate the partial match score
interface ObjectDetails {
    [name: string]: any;
}

export const calculatePartialMatchScore = (name: string, searchValue: string) => {
    const lowerCaseName = name.toLowerCase();
    const lowerCaseSearchValue = searchValue.toLowerCase();
    let score = 0;
    for (let i = 0; i < Math.min(lowerCaseName.length, lowerCaseSearchValue.length); i++) {
        if (lowerCaseName[i] === lowerCaseSearchValue[i]) {
            score++;
        } else {
            break;
        }
    }
    return score;
};

export const compareByPartialMatch = (a: string, b: string, searchValue: string): number => {
    const scoreA = calculatePartialMatchScore(a, searchValue);
    const scoreB = calculatePartialMatchScore(b, searchValue);

    const searchedLowerCase = searchValue.toLowerCase();

    // If there is a complete match, prioritize it
    if (a.toLowerCase() === searchedLowerCase) return -1;
    if (b.toLowerCase() === searchedLowerCase) return 1;

    // Otherwise, sort by the partial match scores
    return scoreB - scoreA; // Sort in descending order of scores
};

// Define a function to sort an array of strings by partial matches
export const sortStringsByPartialMatch = (arrayOfStrings: string[], searchValue: string): string[] => {
    return [...arrayOfStrings].sort((a, b) => compareByPartialMatch(a, b, searchValue));
};

// Define a function to sort an array of objects by partial matches
export const sortByPartialMatch = <T extends ObjectDetails>(arrayOfObjects: readonly T[], searchValue: string, fieldName: string): T[] => {
    return [...arrayOfObjects].sort((a, b) => compareByPartialMatch(a[fieldName], b[fieldName], searchValue));
};
