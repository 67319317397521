import StayPrimaryPortraitIcon from '@mui/icons-material/StayPrimaryPortrait';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';

import { ScreensList, TemplatesLink } from 'constants/routerPath';

const screen = {
    id: 'group-screen',
    title: 'Screen',
    type: 'group',
    children: [
        {
            id: 'screens',
            title: 'Screens',
            type: 'item',
            url: ScreensList,
            icon: StayPrimaryPortraitIcon,
            breadcrumbs: false,
            permission_name: 'screens',
        },
        {
            id: 'templates',
            title: 'Templates',
            type: 'item',
            url: TemplatesLink,
            icon: ViewHeadlineIcon,
            breadcrumbs: false,
            permission_name: 'templates',
        },
    ],
};

export default screen;
