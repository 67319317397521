import React from 'react';

import { GenericDetails } from 'components/Input/Autocomplete';
import { useDebouncedFilterInput } from 'components/Toolbar/hooks/useDebouncedFilterInput';

import CheckIcon from '@mui/icons-material/Check';
import { Autocomplete, AutocompleteProps, Box, Grid, TextField, useTheme } from '@mui/material';

import { DropdownBox } from '../DropdownBox';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

interface SingleSelectProps<T extends GenericDetails>
    extends Omit<AutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>, 'inputValue' | 'renderInput'> {
    options: T[];
    selectedItem?: T | null;
    setSelectedItem: (item: T | null) => void;
    onClear?: () => void;
    handleClose?: () => void;
    name?: string;
    placeholder?: string;
    label?: string;
    isLoading?: boolean;
}

const SingleSelect = React.memo(
    <T extends GenericDetails>({
        selectedItem,
        options,
        setSelectedItem,
        onClear,
        handleClose,
        placeholder,
        isLoading,
        name,
        label,
        ...rest
    }: SingleSelectProps<T>) => {
        const { sortedOptions, handleChange, setLocalInputValue } = useDebouncedFilterInput({
            options,
            selectedItem,
            setSelectedItem,
            onClear,
            handleClose,
        });

        const theme = useTheme();

        return (
            <Autocomplete
                open
                data-locator={`${name}_single_select`}
                sx={{ width: '100%', minWidth: '200px', maxWidth: '100%', p: 2 }}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option?.name)}
                options={sortedOptions}
                autoComplete
                includeInputInList
                value={selectedItem || null}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                noOptionsText="No results"
                // @ts-ignore
                onChange={handleChange}
                onInputChange={(_event, newInputValue) => {
                    if (newInputValue) {
                        setLocalInputValue(newInputValue);
                    }
                }}
                forcePopupIcon={false}
                disableClearable
                disableListWrap
                disabledItemsFocusable
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            autoFocus
                            label={label}
                            placeholder={placeholder}
                            fullWidth
                            sx={{
                                '& .MuiInputBase-root': {
                                    height: '42px',
                                    py: 0,
                                },
                            }}
                        />
                    );
                }}
                disablePortal
                PopperComponent={(props) => (
                    <DropdownBox
                        {...props}
                        style={{
                            width: 'unset',
                        }}
                        maxItems={sortedOptions.length}
                        isLoading={isLoading}
                        isFetched={sortedOptions.length > 0}
                    />
                )}
                renderOption={(props, option, { inputValue, selected }) => {
                    const matches = match(option?.name, inputValue, { insideWords: true });
                    const parts = parse(option?.name, matches);

                    return (
                        <li {...props}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Box
                                    display="flex"
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 4,
                                        whiteSpace: 'pre-line',
                                    }}
                                >
                                    {parts?.map((part, index) => {
                                        return (
                                            <span
                                                key={part.text + index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                    color: part.highlight ? theme.palette.common.black : 'black',
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        );
                                    })}
                                </Box>
                                {selected && (
                                    <Grid display="flex" item alignSelf="flex-end" justifyItems="center">
                                        <CheckIcon color="primary" />
                                    </Grid>
                                )}
                            </Grid>
                        </li>
                    );
                }}
                {...rest}
            />
        );
    }
);

export { SingleSelect };
