import { Box, useMediaQuery } from '@mui/material';

import MobileSection from './MobileSection';
import Profile from './Profile';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <Box sx={{ marginLeft: 'auto' }}>
            {!matchesXs && <Profile />}
            {matchesXs && <MobileSection />}
        </Box>
    );
};

export default HeaderContent;
