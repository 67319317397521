import { createSlice } from '@reduxjs/toolkit';

type User = {
    // fill with user properties
    token: string;
    group?: Array<string>;
    email: string;
};

type UserState = {
    isLoggedIn: boolean;
    user: null | User;
};

const initialState: UserState = {
    isLoggedIn: false,
    user: null,
};

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logIn(state, action) {
            state.isLoggedIn = true;
            state.user = action.payload;
        },
        logOut(state) {
            state.isLoggedIn = initialState.isLoggedIn;
            state.user = initialState.user;
        },
    },
});

export default user.reducer;

export const { logIn, logOut } = user.actions;
