import { Logout } from '@mui/icons-material';
import { List, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';

import PropTypes from 'prop-types';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout }) => {
    const theme = useTheme();

    return (
        <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
            <ListItemButton data-locator="logoutButton" onClick={handleLogout}>
                <ListItemIcon>
                    <Logout fill />
                </ListItemIcon>
                <ListItemText primary="Sign out" />
            </ListItemButton>
        </List>
    );
};

ProfileTab.propTypes = {
    handleLogout: PropTypes.func,
};

export default ProfileTab;
