import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { SelectProjectsResponse } from 'hooks/api/general/useGetSelectProjects';
import useAuth from 'hooks/authentication/useAuth';
import {
    AwardedRoleObject,
    CreateEditRolesAwarded,
    GROUPS,
    Permission,
    PermissionType,
    ProjectsNames,
    RoleNames,
} from 'hooks/permission/constants';

import { getProjectNameById, isPermissionTypesAllowed } from './utils';

import { RootState } from 'store';

export type PermitProps = {
    projectId?: number;
    name?: Permission;
    type: keyof PermissionType;
};

export type GroupsState = {
    [key: string]: {
        role: RoleNames;
    };
};

export type RolePriority = {
    rolePriority?: RoleNames;
    projectPriority?: string;
};

const usePermission = () => {
    const { getPermission } = useAuth();
    const groups = useSelector((state: RootState) => state.generalData.permissionGroups);
    // name of available projects
    const availableProjects = useSelector((state: RootState) => state.generalData.permissionAvailableProjects);
    const availableRoles = useSelector((state: RootState) => state.generalData.permissionAvailableRoles);
    // all projects with short data
    const { projectsList } = useSelector((state: RootState) => state.generalData);
    const priorityGroup = useSelector((state: RootState) => state.generalData.permissionRolePriority);

    const getEditProjectList = useCallback(
        (currentPage?: Permission) => {
            const awardedRoles = currentPage ? AwardedRoleObject[currentPage] : CreateEditRolesAwarded;
            const result: SelectProjectsResponse[] = [];

            Object?.entries(groups).forEach((item) => {
                if (awardedRoles?.includes(item[1]?.role)) {
                    const project = projectsList?.find((projectInfo) => projectInfo.system_name === (item[0] as unknown as ProjectsNames));

                    if (project) {
                        result.push(project);
                    }
                }
            });

            return result;
        },
        [groups, projectsList]
    );

    const getRoleList = async () => {
        const awsPermissionArray: Array<string> = await getPermission();
        let listOfRole: Array<RoleNames> = [];

        const projectWithRole = awsPermissionArray?.filter((element) => element.includes(':'));

        projectWithRole.forEach((group) => {
            const groupSplit = group.split(':');

            const roleName = groupSplit[1] as RoleNames;

            if (roleName) listOfRole = [...new Set([...listOfRole, roleName])];
        });

        return listOfRole;
    };

    const getIsPermit = ({ projectId, type, name }: PermitProps) => {
        const projectName = getProjectNameById(projectId);

        if (projectName) {
            if (!isAvailableProject(projectName)) return false;
            const projectRole = groups[projectName].role;
            const permissionList = GROUPS[projectRole];

            return isPermissionTypesAllowed(permissionList, name, type) ?? false;
        } else {
            const permissionList = priorityGroup?.rolePriority && GROUPS[priorityGroup.rolePriority];

            return isPermissionTypesAllowed(permissionList, name, type) ?? false;
        }
    };

    const isAvailableProject = (project: ProjectsNames) => {
        return availableProjects.includes(project);
    };

    return {
        groups,
        projects: availableProjects,
        availableRoles,
        currentPageAvailableProjects: getEditProjectList,
        getRoleList,
        getIsPermit,
        projectPriority: priorityGroup?.projectPriority,
    };
};

export default usePermission;
