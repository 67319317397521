import { Box, Popover } from '@mui/material';

interface PopperBoxProps {
    children: React.ReactNode;
    anchorEl?: HTMLElement | null;
    handleClose?: () => void;
}

const PopperBox = ({ children, anchorEl, handleClose }: PopperBoxProps) => {
    const open = Boolean(anchorEl);
    const id = open ? 'popover_box' : undefined;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{
                top: '8px',
            }}
        >
            <Box
                sx={{
                    borderRadius: '8px',
                    bgcolor: 'background.paper',
                    boxShadow:
                        '0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)',
                }}
            >
                {children}
            </Box>
        </Popover>
    );
};

export { PopperBox };
