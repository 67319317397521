import { useDispatch } from 'react-redux';

import { Auth, Hub } from 'aws-amplify';

import { logger } from 'utils/logger';
import { ERROR_REASONS } from 'utils/logger/transports/constants';

import { logOut } from 'store/reducers/user';

interface LoginPayload {
    userName: string;
    password: string;
    clientMetaData?: Record<string, string> | undefined;
}

interface EventErrorReasons {
    [key: string]: string;
}

const eventErrorReasons: EventErrorReasons = {
    tokenRefresh: ERROR_REASONS.tokenRefresh,
    tokenRefresh_failure: ERROR_REASONS.tokenRefreshFailed,
};

/**
 * useAuth hook
 *
 * @returns {Object} - Object with login, logout, getPermission, getAccessToken, getUserEmail functions
 */
const useAuth = () => {
    const dispatch = useDispatch();

    const login = async ({ userName, password, clientMetaData }: LoginPayload): Promise<void> => {
        try {
            await Auth.signIn(userName, password, clientMetaData);
        } catch (err: any) {
            logger.error(Error('ACL: login error'), {
                cause: err,
                tags: {
                    reason: ERROR_REASONS.login,
                    errorCode: err.status,
                },
            });
        }
    };

    const getPermission = async () => {
        try {
            const session = await Auth.currentSession();
            return session.getAccessToken().decodePayload()['cognito:groups'];
        } catch (err: any) {
            logger.error(Error('ACL: getPermission error'), {
                cause: err,
                tags: {
                    reason: ERROR_REASONS.permission,
                    errorCode: err.status,
                },
            });
        }
    };

    const getAccessToken = async () => {
        try {
            const session = await Auth.currentSession();
            return session.getIdToken().getJwtToken();
        } catch (err: any) {
            logger.error(Error('ACL: getAccessToken error'), {
                cause: err,
                tags: {
                    reason: ERROR_REASONS.getAccessToken,
                    errorCode: err.status,
                },
            });
        }
    };

    const getUserEmail = async () => {
        try {
            const session = await Auth.currentSession();
            return session.getIdToken().payload.email;
        } catch (err: any) {
            logger.error(Error('ACL: getUserEmail error'), {
                cause: err,
                tags: {
                    reason: ERROR_REASONS.userEmail,
                    errorCode: err.status,
                },
            });
        }
    };

    const logout = async (): Promise<void> => {
        await Auth.signOut();
        localStorage.clear();
        dispatch(logOut());
    };

    const handleSessionError = async (err: any, reason: string): Promise<void> => {
        logger.error(Error(`ACL: ${reason}`), {
            cause: err,
            tags: {
                reason,
                errorCode: err.status,
            },
        });
        await logout();
    };

    Hub.listen('auth', async (data) => {
        const { payload } = data;
        const reason = eventErrorReasons[payload.event];
        if (reason) {
            try {
                await Auth.currentSession();
            } catch (err: any) {
                await handleSessionError(err, reason);
            }
        }
    });

    return {
        login,
        logout,
        getPermission,
        getAccessToken,
        getUserEmail,
    };
};

export default useAuth;
