import { useQuery } from 'react-query';

import { request } from 'api/request';

import { logger } from 'utils/logger';
import { ERROR_REASONS } from 'utils/logger/transports/constants';

import { BaseHookProps, ERROR_DATA } from '../type';

interface UseGetUserParams extends BaseHookProps<any> {
    id?: string;
}

const apiRequest = async (selectedUserId?: string) => {
    return await request(`admin/users/${selectedUserId}`);
};

export const useGetUser = ({ id, onError, onSuccess, onSettled }: UseGetUserParams) => {
    return useQuery<any, Promise<ERROR_DATA<unknown>>>(['user', id], async () => apiRequest(id), {
        refetchOnWindowFocus: false,
        enabled: Boolean(id),
        onError: async (err) => {
            const error = await err;
            onError && onError(error);

            logger.error(Error('Error fetching user:'), {
                cause: error,
                tags: {
                    reason: ERROR_REASONS.getUser,
                    errorCode: error.status,
                },
            });
        },
        onSuccess,
        onSettled,
    });
};
