import React, { useCallback } from 'react';

import { Box, Button, Typography } from '@mui/material';

import { WrapperToolbar } from './components/WrapperToolbar';
import { useToolbarFields } from './hooks/useToolbarFields';
import { ToolbarProps } from './types';
import { checkEmptyValues } from './utils';

const Toolbar = ({ toolbar: { fields, handleReset }, screenName = '' }: ToolbarProps) => {
    const { formData, setFieldValue, handleResetFilter } = useToolbarFields(screenName, fields);

    const handleResetToolbar = useCallback(() => {
        handleResetFilter();
        handleReset && handleReset();
    }, [handleResetFilter, handleReset]);

    return (
        <Box
            padding="16px"
            display="flex"
            justifyContent="space-between"
            gap="16px"
            sx={{
                overflowX: 'auto',
                width: '100%',
            }}
        >
            <WrapperToolbar fields={fields} formData={formData} setFieldValue={setFieldValue} />
            <Box display="flex" height="fit-content" gap="8px">
                <Button
                    disabled={!checkEmptyValues(formData)}
                    data-locator="resetButton"
                    size="small"
                    variant="text"
                    color="primary"
                    onClick={handleResetToolbar}
                >
                    <Typography variant="button">RESET</Typography>
                </Button>
            </Box>
        </Box>
    );
};

export { Toolbar };
