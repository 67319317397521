import { GenericDetails } from 'components/Input/Autocomplete';

import { createSlice } from '@reduxjs/toolkit';

interface ToolbarState {
    [key: string]: {
        [key: string]: GenericDetails;
    };
}

const initialState: ToolbarState = {};

const toolbar = createSlice({
    name: 'toolbar',
    initialState,
    reducers: {
        generateToolbarFields: (state, action) => {
            state[action.payload.name] = action.payload.value;
        },
        setToolbarValue: (state, action) => {
            const { name, fieldName, fieldValue } = action.payload;

            state[name] = {
                ...state[name],
                [fieldName]: fieldValue,
            };
        },
    },
});

export default toolbar.reducer;

export const { generateToolbarFields, setToolbarValue } = toolbar.actions;
