import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GenericDetails } from 'components/Input/Autocomplete';

import { Field } from '../types';
import { generateValues } from '../utils';
import { generateToolbarFields, setToolbarValue } from 'store/reducers/toolbar';

import { RootState } from 'store';

type ValuesStore = { [key: string]: GenericDetails };

const useToolbarFields = (screenName: string, fields: Array<Array<Field>>) => {
    const dispatch = useDispatch();
    const formData = useSelector((state: RootState) => state.toolbar[screenName]);

    const generateValuesStore = useCallback(() => {
        const values = fields.reduce<ValuesStore>((acc, layer) => {
            layer.forEach((field) => {
                acc[field.name] = field.defaultValue || generateValues();
            });
            return acc;
        }, {});

        dispatch(generateToolbarFields({ name: screenName, value: values }));
    }, [fields, screenName, dispatch]);

    const generateEmptyValues = useCallback(() => {
        const values = fields.reduce<ValuesStore>((acc, layer) => {
            layer.forEach((field) => {
                acc[field.name] = generateValues();
            });
            return acc;
        }, {});

        dispatch(generateToolbarFields({ name: screenName, value: values }));
    }, [fields]);

    const setFieldValue = (fieldName: string, fieldValue: GenericDetails) => {
        dispatch(setToolbarValue({ name: screenName, fieldName, fieldValue }));
    };

    const handleResetFilter = () => {
        generateEmptyValues();
    };

    useEffect(() => {
        generateValuesStore();
    }, []);

    return {
        setFieldValue,
        handleResetFilter,
        formData: formData || {},
    };
};

export { useToolbarFields };
