import AutoGraphIcon from '@mui/icons-material/AutoGraph';

import { LTVCoefficientList } from 'constants/routerPath';

const analytics = {
    id: 'group-analytics',
    title: 'Analytics',
    type: 'group',
    children: [
        {
            id: 'ltv-coefficient',
            title: 'LTV coefficient',
            type: 'item',
            url: LTVCoefficientList,
            icon: AutoGraphIcon,
            breadcrumbs: false,
            permission_name: 'rebill-rates',
        },
    ],
};

export default analytics;
