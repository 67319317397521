import { request } from 'api/request';

import { PROVIDER_NAMES } from './types';

const makeRequest = async (endpoint: string, params?: Record<string, string>) => {
    return await request(endpoint, { params });
};

const PROVIDER_REQUEST_MAP: Record<PROVIDER_NAMES, (name: string, additionalParams?: object) => Promise<any>> = {
    // Branches page
    [PROVIDER_NAMES.BRANCH_URL_SEGMENT]: async (url_segment: string, additionalParams = {}) => {
        return await makeRequest('admin/autocomplet/branches', {
            ...additionalParams,
            url_segment,
        });
    },
    // Links page
    [PROVIDER_NAMES.LINKS_BRANCH]: async (url_segment: string, additionalParams = {}) => {
        return await makeRequest('admin/autocomplet/branches', {
            ...additionalParams,
            url_segment,
        });
    },
    [PROVIDER_NAMES.LINKS_LINK_ID]: async (link_id: string, additionalParams = {}) => {
        return await makeRequest('admin/autocomplet/links', {
            ...additionalParams,
            link_id,
        });
    },
    [PROVIDER_NAMES.LINKS_EXPERIMENT_NAME]: async (experiment_name: string, additionalParams = {}) => {
        return await makeRequest('admin/autocomplet/links', {
            ...additionalParams,
            experiment_name,
        });
    },
    // Screens page
    [PROVIDER_NAMES.SCREENS_NAME]: async (name: string, additionalParams = {}) => {
        return await makeRequest('admin/autocomplet/screens', {
            ...additionalParams,
            name,
        });
    },
    [PROVIDER_NAMES.SCREENS_TEMPLATE_ID]: async (template_name: string, additionalParams = {}) => {
        return await makeRequest('admin/autocomplet/templates', {
            ...additionalParams,
            name: template_name,
        });
    },
    // Templates page
    [PROVIDER_NAMES.TEMPLATES_NAME]: async (name: string, additionalParams = {}) => {
        return await makeRequest('admin/autocomplet/templates', {
            ...additionalParams,
            name,
        });
    },
    // Localization page
    [PROVIDER_NAMES.LOCALIZATION_TEXT]: async (text: string, additionalParams = {}) => {
        return await makeRequest('admin/autocomplet/localizations', {
            ...additionalParams,
            text,
        });
    },
    // Product codes page
    [PROVIDER_NAMES.PRODUCT_CODES_NAME]: async (name: string, additionalParams = {}) => {
        return await makeRequest('admin/autocomplet/product-codes', {
            ...additionalParams,
            name,
        });
    },
    // Product plan page
    [PROVIDER_NAMES.PRODUCT_PLAN_NAME]: async (name: string, additionalParams = {}) => {
        return await makeRequest('admin/autocomplet/products', {
            ...additionalParams,
            name,
        });
    },
    // Theme page
    [PROVIDER_NAMES.THEME_NAME]: async (name: string, additionalParams = {}) => {
        return await makeRequest('admin/autocomplet/themes', {
            ...additionalParams,
            name,
        });
    },
    empty: async () => {},
};

export { PROVIDER_REQUEST_MAP };
