export enum PROVIDER_NAMES {
    BRANCH_URL_SEGMENT = 'branch_url_segment',
    LINKS_BRANCH = 'links_branch',
    LINKS_LINK_ID = 'links_link_id',
    LINKS_EXPERIMENT_NAME = 'links_experiment_name',
    SCREENS_NAME = 'screens_name',
    SCREENS_TEMPLATE_ID = 'screens_template_id',
    TEMPLATES_NAME = 'templates_name',
    LOCALIZATION_TEXT = 'localization_text',
    PRODUCT_CODES_NAME = 'product_codes_name',
    PRODUCT_PLAN_NAME = 'product_plan_name',
    THEME_NAME = 'theme_name',
    EMPTY = 'empty',
}

export interface OptionsProviderProps {
    additionalParams?: Record<string, any>;
    onSuccess?: (data: any) => void;
    enabled?: boolean;
}
