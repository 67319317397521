import { useCallback } from 'react';

import { Field } from 'components/Toolbar/types';
import { generateValues } from 'components/Toolbar/utils';

import { Box } from '@mui/material';

import { ChipToolbar } from './components/ChipToolbar';
import { FieldRenderer } from './components/FieldRenderer';

interface ToolbarProps {
    fields: Array<Array<Field>>;
    setFieldValue: (name: string, value: any) => void;
    formData: Record<string, any>;
}

const WrapperToolbar = ({ fields, formData, setFieldValue }: ToolbarProps) => {
    const handleClear = useCallback(
        (name: string) => {
            setFieldValue(name, generateValues());
        },
        [setFieldValue]
    );

    return (
        <Box display="flex" flexDirection="column" gap="16px">
            {fields.map((layer, fieldsIndex) => (
                <Box display="flex" gap="8px" alignItems="center" flexWrap="wrap" key={fieldsIndex}>
                    {layer.map((field) => {
                        const key = `${field.name}_${field.label}`;

                        if (field.variation === 'default' || !field.variation) {
                            return <FieldRenderer key={key} field={field} formData={formData} setFieldValue={setFieldValue} />;
                        }

                        return (
                            <ChipToolbar
                                key={key}
                                dataLocator={`${field.name}_chip`}
                                label={field.label}
                                value={field.defaultValue}
                                onClear={() => handleClear(field.name)}
                            >
                                <FieldRenderer field={field} formData={formData} setFieldValue={setFieldValue} />
                            </ChipToolbar>
                        );
                    })}
                </Box>
            ))}
        </Box>
    );
};

export { WrapperToolbar };
