import { useQuery } from 'react-query';

import { ERROR_DATA } from 'hooks/api/type';

import { cleanSearchParams, limitText } from 'utils/common';
import { logger } from 'utils/logger';
import { ERROR_REASONS } from 'utils/logger/transports/constants';

import { MAX_LIMIT_SYMBOL_LENGTH } from 'constants/common';

import { PROVIDER_REQUEST_MAP } from './constants';
import { OptionsProviderProps, PROVIDER_NAMES } from './types';

/**
 * Hook for fetching data from provider for autocomplete
 * @param providerName - provider name for fetch data
 * @param value - value for search
 * @param optionsProvider - additional options for provider request
 * @returns data, isLoading, isError, and error status
 */
const useFilterProvider = (providerName = PROVIDER_NAMES.EMPTY, value = '', optionsProvider?: OptionsProviderProps) => {
    const { additionalParams, onSuccess } = optionsProvider || {};

    const queryFn = async () => {
        const limitedText = limitText(value, MAX_LIMIT_SYMBOL_LENGTH);
        const cleanedParams = cleanSearchParams(additionalParams || {});
        return PROVIDER_REQUEST_MAP[providerName](limitedText, cleanedParams);
    };

    const { isLoading, isFetched, isError, isFetching, error, data } = useQuery(['toolbar-filter', providerName, value], queryFn, {
        enabled: (Boolean(providerName) && !!value) || providerName === 'empty',
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            return onSuccess ? onSuccess(data) : null;
        },
        refetchOnMount: false,
        onError: async (err: Promise<ERROR_DATA<unknown>>) => {
            const error = await err;

            logger.error(Error(`Error fetching autocomplete NAME_${providerName}:`), {
                cause: error,
                tags: {
                    reason: ERROR_REASONS.filterAutocomplete,
                    errorCode: error?.status,
                },
            });
        },
    });

    return { data, isLoading, isFetched, isFetching, isError, error };
};

export { useFilterProvider };
