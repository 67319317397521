import { GenericDetails } from 'components/Input/Autocomplete';

import { Field, FormDataType } from './types';

export const generateValues = (newItem?: GenericDetails): GenericDetails => {
    return {
        id: newItem?.id || '',
        name: newItem?.name || '',
        value: newItem?.value || '',
    };
};

export const checkEmptyValues = (data?: FormDataType) => {
    let status = false;

    if (!data) return status;

    Object.values(data).forEach((value) => {
        if ((value.id !== '' || value.value !== '') && value.name !== '') {
            status = true;
        }
    });

    return status;
};

// function that takes in a Field object and returns an array of objects with the name and id properties
export const normalizeFieldOptions = (
    options: Field['options'],
    defaultOptionName: string,
    defaultOptionId: string,
    defaultOptionValue: string
) => {
    if (!options) return [];

    return options.map((option) => {
        if (typeof option === 'string') {
            return {
                name: option,
                id: option,
                value: option,
            };
        }

        return {
            name: option[defaultOptionName],
            id: option[defaultOptionId],
            value: option[defaultOptionValue],
        };
    });
};
