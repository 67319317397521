import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';

import SentryService from 'libs/Sentry';

import 'assets/third-party/apex-chart.css';

import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import 'simplebar/src/simplebar.css';

import { persistor, store } from 'store';

SentryService.init();

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
    <ReduxProvider store={store}>
        <PersistGate persistor={persistor}>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </PersistGate>
    </ReduxProvider>
);
