import { CountryList } from 'hooks/api/general/useGetCountryList';
import { SelectProjectsResponse } from 'hooks/api/general/useGetSelectProjects';
import { ProjectsNames, RoleNames } from 'hooks/permission/constants';
import { GroupsState, RolePriority } from 'hooks/permission/usePermission';

import { createSlice } from '@reduxjs/toolkit';

type GeneralState = {
    projectsList: SelectProjectsResponse[];
    countryList?: CountryList[];
    pageSize: number;
    permissionGroups: GroupsState;
    permissionAvailableRoles: Array<RoleNames>;
    permissionAvailableProjects: Array<ProjectsNames>;
    permissionEditProjectList: Array<SelectProjectsResponse>;
    permissionRolePriority: RolePriority;
};

const initialState: GeneralState = {
    projectsList: [],
    countryList: [],
    pageSize: 10,
    permissionGroups: {},
    permissionAvailableRoles: [],
    permissionAvailableProjects: [],
    permissionEditProjectList: [],
    permissionRolePriority: {},
};

const generalData = createSlice({
    name: 'generalData',
    initialState,
    reducers: {
        setProjectsList(state, action) {
            state.projectsList = action.payload;
        },
        setCountryList(state, action) {
            state.countryList = action.payload;
        },
        setPagination(state, action) {
            state.pageSize = action.payload;
        },
        setPermissionGroups(state, action) {
            state.permissionGroups = action.payload;
        },
        setPermissionAvailableRoles(state, action) {
            state.permissionAvailableRoles = action.payload;
        },
        setPermissionAvailableProjects(state, action) {
            state.permissionAvailableProjects = action.payload;
        },
        setPermissionEditProjectList(state, action) {
            state.permissionEditProjectList = action.payload;
        },
        setPermissionPriority(state, action) {
            state.permissionRolePriority = action.payload;
        },
    },
});

export default generalData.reducer;

export const {
    setProjectsList,
    setPagination,
    setCountryList,
    setPermissionGroups,
    setPermissionAvailableRoles,
    setPermissionAvailableProjects,
    setPermissionEditProjectList,
    setPermissionPriority,
} = generalData.actions;
