import { useMemo } from 'react';

import { createTheme, CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeOptions, ThemeProvider } from '@mui/material/styles';

import { FONTS_NAME } from './fonts';
import componentsOverride from './overrides';
import Palette from './palette';
import Typography from './typography';

interface ThemeCustomizationProps {
    children: React.ReactNode;
}

export default function ThemeCustomization({ children }: ThemeCustomizationProps) {
    const theme = Palette('light');

    const themeTypography = Typography(FONTS_NAME);

    const themeOptions = useMemo(
        () => ({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 768,
                    md: 1024,
                    lg: 1266,
                    xl: 1536,
                },
            },
            direction: 'ltr',
            mixins: {
                toolbar: {
                    minHeight: 60,
                    paddingTop: 8,
                    paddingBottom: 8,
                },
            },
            palette: theme.palette,
            typography: themeTypography,
        }),
        [theme, themeTypography]
    ) as ThemeOptions;

    const themes = createTheme(themeOptions);
    themes.components = componentsOverride(themes);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
