import { FeaturesTypes } from './types';

export enum REQUEST_TYPES {
    create = 'create',
    edit = 'edit',
    delete = 'delete',
}

export enum REQUEST_METHODS {
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

export enum LOGS_QUERY_PARAMS {
    feature = 'feature',
    entity_id = 'entity_id',
}

export const LOGS_QUERY_PARAMS_TO_RESET = [LOGS_QUERY_PARAMS.feature, LOGS_QUERY_PARAMS.entity_id];

export const RequestTypesList: Array<{ name: REQUEST_TYPES; id: REQUEST_METHODS }> = [
    { name: REQUEST_TYPES.create, id: REQUEST_METHODS.POST },
    { name: REQUEST_TYPES.edit, id: REQUEST_METHODS.PUT },
    { name: REQUEST_TYPES.delete, id: REQUEST_METHODS.DELETE },
];
export const FeatureTypesList: Array<FeaturesTypes> = [
    FeaturesTypes.branches,
    FeaturesTypes.links,
    FeaturesTypes.screens,
    FeaturesTypes.templates,
    FeaturesTypes.localization,
    FeaturesTypes.themes,
    FeaturesTypes.flow,
    FeaturesTypes.productCodes,
    FeaturesTypes.products,
    FeaturesTypes.projects,
    FeaturesTypes.users,
];
