import { forwardRef, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import PermissionWrapper from 'components/PermissionWrapper';

import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material';

import { MenuContext } from 'layout/MainLayout';

import PropTypes from 'prop-types';

const NavItem = ({ item, level }) => {
    const theme = useTheme();
    const { isDrawerOpen, openItem, setOpenItem } = useContext(MenuContext);

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />) };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        setOpenItem(id);
    };

    const Icon = item.icon;
    const itemIcon = item.icon ? <Icon style={{ fontSize: isDrawerOpen ? '1rem' : '1.25rem' }} /> : false;

    const isSelected = openItem === item.id;

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            setOpenItem(item.id);
        }
        // eslint-disable-next-line
    }, []);

    const textColor = 'text.primary';
    const iconSelectedColor = 'primary.main';

    function toCamelCase(sentence) {
        return sentence
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join('');
    }

    return (
        <PermissionWrapper type="get" name={item.permission_name}>
            <ListItemButton
                {...listItemProps}
                disabled={item.disabled}
                onClick={() => itemHandler(item.id)}
                selected={isSelected}
                data-locator={`sidebar${toCamelCase(item.title)}`}
                sx={{
                    zIndex: 1201,
                    pl: isDrawerOpen ? `${level * 28}px` : 1.5,
                    py: !isDrawerOpen && level === 1 ? 1.25 : 1,
                    ...(isDrawerOpen && {
                        '&:hover': {
                            bgcolor: 'primary.lighter',
                        },
                        '&.Mui-selected': {
                            bgcolor: 'primary.lighter',
                            borderRight: `2px solid ${theme.palette.primary.main}`,
                            color: iconSelectedColor,
                            '&:hover': {
                                color: iconSelectedColor,
                                bgcolor: 'primary.lighter',
                            },
                        },
                    }),
                    ...(!isDrawerOpen && {
                        '&:hover': {
                            bgcolor: 'transparent',
                        },
                        '&.Mui-selected': {
                            '&:hover': {
                                bgcolor: 'transparent',
                            },
                            bgcolor: 'transparent',
                        },
                    }),
                }}
            >
                {itemIcon && (
                    <ListItemIcon
                        sx={{
                            minWidth: 28,
                            color: isSelected ? iconSelectedColor : textColor,
                            ...(!isDrawerOpen && {
                                borderRadius: 1.5,
                                width: 36,
                                height: 36,
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                    bgcolor: 'secondary.lighter',
                                },
                            }),
                            ...(!isDrawerOpen &&
                                isSelected && {
                                    bgcolor: 'primary.lighter',
                                    '&:hover': {
                                        bgcolor: 'primary.lighter',
                                    },
                                }),
                        }}
                    >
                        {itemIcon}
                    </ListItemIcon>
                )}
                {(isDrawerOpen || (!isDrawerOpen && level !== 1)) && (
                    <ListItemText
                        primary={
                            <Typography variant="body2" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
                                {item.title}
                            </Typography>
                        }
                    />
                )}
                {(isDrawerOpen || (!isDrawerOpen && level !== 1)) && item.chip && (
                    <Chip
                        color={item.chip.color}
                        variant={item.chip.variant}
                        size={item.chip.size}
                        label={item.chip.label}
                        avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                    />
                )}
            </ListItemButton>
        </PermissionWrapper>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number,
};

export default NavItem;
