import React, { forwardRef } from 'react';

import { Card, CardContent, CardHeader, Divider, Typography, useTheme } from '@mui/material';

const headerSX = {
    p: 2.5,
    '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' },
};

type Props = {
    border?: boolean;
    boxShadow?: boolean;
    contentSX?: object;
    darkTitle?: boolean;
    divider?: boolean;
    elevation?: number;
    secondary?: React.ReactNode;
    shadow?: string;
    sx?: object;
    title?: string;
    content?: boolean;
    children?: React.ReactNode;
};

const MainCard = forwardRef<HTMLDivElement | null, Props>(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentSX = {},
            darkTitle,
            divider = true,
            elevation,
            secondary,
            shadow,
            sx = {},
            title,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();
        boxShadow = theme.palette.mode === 'dark' ? boxShadow || true : boxShadow;

        return (
            <Card
                elevation={elevation || 0}
                ref={ref}
                {...others}
                sx={{
                    ...sx,
                    border: border ? '1px solid' : 'none',
                    borderRadius: 2,
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey[800],
                    boxShadow: boxShadow && (!border || theme.palette.mode === 'dark') ? shadow || theme.shadows[4] : 'inherit',
                    ':hover': {
                        boxShadow: boxShadow ? shadow || theme.shadows[4] : 'inherit',
                    },
                    '& pre': {
                        m: 0,
                        p: '16px !important',
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '0.75rem',
                    },
                }}
            >
                {/* card header and action */}
                {!darkTitle && title && (
                    <CardHeader sx={headerSX} titleTypographyProps={{ variant: 'subtitle1' }} title={title} action={secondary} />
                )}
                {darkTitle && title && (
                    <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
                )}

                {/* content & header divider */}
                {title && divider && <Divider />}

                {/* card content */}
                {content && <CardContent sx={contentSX}>{children}</CardContent>}
                {!content && children}
            </Card>
        );
    }
);

export default MainCard;
