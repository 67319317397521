import { Box, Card, Typography } from '@mui/material';

import PermissionsGrid from './PermissionsGrid';

const Permissions = () => {
    return (
        <div>
            <Box display="flex" sx={{ mb: 2 }}>
                <Typography variant="h4" component="h4">
                    Users
                </Typography>
            </Box>
            <Card>
                <PermissionsGrid />
            </Card>
        </div>
    );
};

export default Permissions;
