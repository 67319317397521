import { SearchActionButtons } from 'components/Toolbar/components/Fields/SearchActionButtons/SearchActionButtons';

import { Box, CircularProgress, Divider, Typography } from '@mui/material';

import { StyledAutocompletePopper } from './styled';

interface DropdownBoxProps {
    maxItems?: number;
    onApply?: () => void;
    onCancel?: () => void;
    withActionButtons?: boolean;
    isLoading?: boolean;
    isFetched?: boolean;
    isFetching?: boolean;
    style?: object;
    withResults?: boolean;
    open?: boolean;
    isFieldEmpty?: boolean;
}

const DropdownBox = (props: DropdownBoxProps) => {
    const { maxItems, onApply, onCancel, withActionButtons, isFieldEmpty, isLoading, isFetched, isFetching, withResults, open, ...other } =
        props;

    const isHasOptions = Boolean(maxItems && maxItems >= 0);
    const isNoResultsVisible = !isHasOptions && isFetched && !isFetching && !isFieldEmpty;
    const isVisibleResultsCounter = withResults && isHasOptions;
    const isVisibleDivider = (isFetched && isHasOptions) || isNoResultsVisible || isFetching;

    return (
        <Box overflow="hidden" maxWidth="600px">
            {open && (
                <>
                    {isVisibleDivider && (
                        <Divider
                            sx={{
                                marginBottom: '12px',
                            }}
                        />
                    )}
                    {isVisibleResultsCounter && (
                        <Typography variant="overline" mx={2}>
                            Results ({maxItems})
                        </Typography>
                    )}
                    {isNoResultsVisible && (
                        <Box p={1} height="150px" display="flex" justifyContent="center" alignItems="center">
                            <Typography variant="body1" color="text.secondary">
                                No results
                            </Typography>
                        </Box>
                    )}
                    {isFetching && (
                        <Box p={1} height="150px" display="flex" justifyContent="center" alignItems="center">
                            <CircularProgress />
                        </Box>
                    )}
                    {isFetched && <StyledAutocompletePopper {...other} />}
                </>
            )}
            {withActionButtons ? <SearchActionButtons onApply={onApply} onCancel={onCancel} /> : <Box pb="12px" />}
        </Box>
    );
};

export { DropdownBox };
