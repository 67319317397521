import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withAuthenticator } from '@aws-amplify/ui-react';

import usePermission from 'hooks/permission/usePermission';

import { getCapitalize, getNames } from 'utils/common';

import {
    Avatar,
    Box,
    ButtonBase,
    Chip,
    ClickAwayListener,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';

import ProfileTab from './ProfileTab';
import { logOut } from 'store/reducers/user';
import Transitions from 'ui-elements/@extended/Transitions';
import MainCard from 'ui-elements/MainCard';

import PropTypes from 'prop-types';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = ({ signOut }) => {
    const theme = useTheme();
    const userEmail = useSelector((state) => state.user.user?.email);
    const projectList = useSelector((state) => state.generalData.projectsList);
    const { availableRoles, projects } = usePermission();
    const dispatch = useDispatch();
    const handleLogout = async () => {
        // logout
        dispatch(logOut());
        signOut();
        localStorage.clear();
    };

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const [value] = useState(0);

    const iconBackColorOpen = 'grey.300';

    const getProjectColor = (projectName) => projectList?.find((project) => projectName === project.system_name.toString())?.color;

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }} data-locator="openProfileButton">
            <ButtonBase
                sx={{
                    p: 0.25,
                    bgcolor: open ? iconBackColorOpen : 'transparent',
                    borderRadius: 1,
                    '&:hover': { bgcolor: 'secondary.lighter' },
                }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
                    <Avatar>{getNames(userEmail)?.name?.[0] + getNames(userEmail)?.surName?.[0]}</Avatar>
                    <Typography variant="body1">{userEmail}</Typography>
                </Stack>
            </ButtonBase>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        {open && (
                            <Paper
                                data-locator="profileModal"
                                sx={{
                                    boxShadow: theme.shadows[4],
                                    width: 290,
                                    minWidth: 240,
                                    maxWidth: 290,
                                    [theme.breakpoints.down('md')]: {
                                        maxWidth: 250,
                                    },
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MainCard elevation={0} border={false} content={false}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                padding: '8px 16px',
                                                gap: '12px',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Avatar>{getNames(userEmail)?.name?.[0] + getNames(userEmail)?.surName?.[0]}</Avatar>
                                            <Typography variant="body1">
                                                {getNames(userEmail)?.name + ' ' + getNames(userEmail)?.surName}
                                            </Typography>
                                        </Box>
                                        <hr style={{ margin: '0' }} />
                                        <MenuList>
                                            <MenuItem sx={{ display: 'flex', gap: '4px', alignItems: 'baseline' }}>
                                                Role:
                                                <Typography sx={{ whiteSpace: 'normal' }} color="textSecondary">
                                                    {availableRoles.map((item, index) =>
                                                        getCapitalize(index === availableRoles.length - 1 ? item : item + ', ')
                                                    )}
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem sx={{ display: 'flex', gap: '4px', alignItems: 'baseline' }}>
                                                Projects:
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                                                    {projects.map((item) => (
                                                        <Chip
                                                            key={item}
                                                            variant="outlined"
                                                            style={{ backgroundColor: getProjectColor(item), borderRadius: '25px' }}
                                                            label={item}
                                                        >
                                                            {item}
                                                        </Chip>
                                                    ))}
                                                </Box>
                                            </MenuItem>
                                            <hr />
                                            <TabPanel value={value} index={0} dir={theme.direction}>
                                                <ProfileTab handleLogout={handleLogout} />
                                            </TabPanel>
                                        </MenuList>
                                    </MainCard>
                                </ClickAwayListener>
                            </Paper>
                        )}
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default withAuthenticator(Profile);
