import { Box, Button, Divider } from '@mui/material';

interface SearchActionButtonsProps {
    onApply?: () => void;
    onCancel?: () => void;
}

const SearchActionButtons = ({ onApply, onCancel }: SearchActionButtonsProps) => {
    return (
        <>
            <Divider />
            <Box display="flex" justifyContent="space-between" p={2} gap={1}>
                <Button
                    data-locator="cancelButtonDropdown"
                    variant="text"
                    size="large"
                    style={{
                        textTransform: 'uppercase',
                    }}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    data-locator="applyButtonDropdown"
                    variant="contained"
                    size="large"
                    style={{
                        textTransform: 'uppercase',
                    }}
                    onClick={onApply}
                >
                    Apply
                </Button>
            </Box>
        </>
    );
};

export { SearchActionButtons };
