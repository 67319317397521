import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsBackupRestore from '@mui/icons-material/SettingsBackupRestore';

import { AuditLogs, ProjectsLink, UsersLink } from 'constants/routerPath';

const admin = {
    id: 'group-admin',
    title: 'Admin',
    type: 'group',
    children: [
        {
            id: 'projects',
            title: 'Projects',
            type: 'item',
            url: ProjectsLink,
            icon: BusinessCenterIcon,
            breadcrumbs: false,
            permission_name: 'project',
        },
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: UsersLink,
            icon: PeopleAltIcon,
            breadcrumbs: false,
            permission_name: 'users',
        },
        {
            id: 'audit-log',
            title: 'Audit logs',
            type: 'item',
            url: AuditLogs,
            icon: SettingsBackupRestore,
            breadcrumbs: false,
            permission_name: 'audit-log',
        },
    ],
};

export default admin;
