import { GroupsType } from 'hooks/permission/constants';

import { REQUEST_TYPES } from './constants';

export type AuditLogRecord = {
    after: DiffType;
    before: DiffType;
    email: string;
    entity_id: number;
    feature: string;
    id: number;
    method: REQUEST_TYPES;
    project_id: number;
    roles: GroupsType | null;
    timestamp: string;
};

export enum FeaturesTypes {
    branches = 'branches',
    links = 'links',
    screens = 'screens',
    templates = 'templates',
    localization = 'localization',
    themes = 'themes',
    flow = 'flow',
    productCodes = 'product-codes',
    products = 'products',
    projects = 'projects',
    users = 'users',
}

export type AuditLogListResponse = {
    items: Array<AuditLogRecord>;
    _meta: {
        page: number;
        per_page: number;
        total_pages: number;
        total_items: number;
    };
};

export type DiffType = Record<string, unknown>;

export type RequestedParam = {
    name: string;
    id: string;
};

export type LinkGenerationOptionsType = {
    type: FeaturesTypes;
    id?: number;
};

export type FeatureConfig = {
    getIdValue: (row: AuditLogRecord, value: number) => number;
    getHref: (idValue: number, type: FeaturesTypes) => string | undefined;
    getTooltipText: (href: string | undefined) => string;
};
