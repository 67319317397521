import { SeverityLevel } from '@sentry/react';

import { isExceptionLogFnParams, Level, TransportFn } from 'libs/logger';
import SentryInstance from 'libs/Sentry';

type Primitive = number | string | boolean | bigint | symbol | null | undefined;

const LOG_LEVEL_TO_SEVERITY_MAP: Record<Level, SeverityLevel | undefined> = {
    fatal: 'fatal',
    error: 'error',
    warn: 'warning',
    info: 'info',
    debug: 'debug',
    trace: 'debug',
};

const getTagsFromParams = (paramsList: unknown[]): Record<string, Primitive> => {
    for (const param of paramsList) {
        if (param && typeof param === 'object') {
            const { tags } = param as { tags: unknown };

            if (tags && typeof tags === 'object') {
                return tags as Record<string, Primitive>;
            }
        }
    }

    return {};
};

export const transport: TransportFn = (params) => {
    const { level, levelsConfig, methodFnArgs, isLevelEnabled } = params;
    const { value: levelValue } = levelsConfig[level];

    // capture all logs above 'warn' level as exceptions by sentry
    if (levelValue > levelsConfig.warn.value && isExceptionLogFnParams(params)) {
        const { error } = params;

        const tags = {
            // add tags from logger arguments
            ...getTagsFromParams(methodFnArgs),
        };

        SentryInstance.sentry.withScope((scope) => {
            try {
                for (const tagName of Object.keys(tags)) {
                    scope.setTag(tagName, tags[tagName]);
                }

                SentryInstance.sentry.captureException(error ? error : new Error(methodFnArgs.join(', ')));
            } catch (err) {
                SentryInstance.sentry.captureException(err);
            }
        });

        return;
    }

    // add logs for disabled levels and below to sentry breadcrumbs
    if (!isLevelEnabled) {
        return SentryInstance.sentry.addBreadcrumb({
            type: 'debug',
            category: 'console',
            message: methodFnArgs.join(', '),
            level: LOG_LEVEL_TO_SEVERITY_MAP[level],
            data: { arguments: methodFnArgs, logger: 'console' },
        });
    }
};
