import isEmpty from 'lodash/isEmpty';

import { MAX_LIMIT_SYMBOL_LENGTH } from 'constants/common';
import { QA_EMAILS } from 'constants/flow';

const toTitleCase = (str: string) =>
    str.replace('_', ' ').replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

const cleanSearchParams = (params: object) => {
    return Object.fromEntries(Object.entries(params).filter(([_key, value]) => value !== '' && value !== null && value !== undefined));
};

const getCapitalize = (str: string) => {
    if (!str) return '';
    return str[0].toUpperCase() + str.substring(1);
};

const prepareFilterPayload = (filter: Record<string, any> | undefined, listKeyToUpdate: string[] = []): Record<string, any> => {
    if (!filter) return {};

    const newFilter: Record<string, any> = {};

    for (const [key, value] of Object.entries(filter)) {
        let keyFilter = key;

        if (listKeyToUpdate.includes(key)) {
            const suffix = value?.id ? '_id' : '_name';
            keyFilter += suffix;
        }

        const limitedValue = value?.id || value?.name ? limitText(value.id || value.name, MAX_LIMIT_SYMBOL_LENGTH) : undefined;

        if (limitedValue) {
            newFilter[keyFilter] = limitedValue;
        }
    }

    return newFilter;
};

const getNames = (email: string | null) => {
    const isAqaEmail = QA_EMAILS.includes(email || '');
    const namesFromEmail = email?.split?.('@')?.[0]?.split?.('.');

    if (isAqaEmail) {
        return {
            name: 'Q',
            surName: 'A',
        };
    }

    return {
        name: (namesFromEmail?.[0] && getCapitalize(namesFromEmail?.[0])) || '',
        surName: (namesFromEmail?.[1] && getCapitalize(namesFromEmail?.[1])) || '',
    };
};

const getReplaceQuotes = (str: string) => {
    return str.replaceAll("'", '"');
};

const omitKeys = <T extends Record<string, any>>(obj: T, keys: string[]) => {
    const newObj = { ...obj };
    keys.forEach((key) => delete newObj[key]);
    return newObj;
};

const isValidJSON = (str?: string) => {
    if (!str) return false;

    let isValid;

    try {
        const parserJson = JSON.parse(str);
        if (!isEmpty(parserJson)) {
            isValid = true;
        } else {
            isValid = false;
        }
    } catch {
        isValid = false;
    }

    return isValid;
};

const isObject = (value: any) => {
    return value && typeof value === 'object' && value.constructor === Object;
};

const limitText = (text: string, limit: number) => {
    return text.length >= limit ? text.slice(0, limit) : text;
};

export {
    toTitleCase,
    cleanSearchParams,
    getReplaceQuotes,
    getCapitalize,
    getNames,
    omitKeys,
    isObject,
    isValidJSON,
    limitText,
    prepareFilterPayload,
};
