import BugReportIcon from '@mui/icons-material/BugReport';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

const BUG_REPORT_URL = 'https://app.asana.com/0/1206839858863935/1206841946562744';
const FEATURE_REQUEST_URL = 'https://app.asana.com/0/1205783490459816/1205795015261212';

const support = {
    id: 'group-support',
    title: 'Support',
    type: 'group',
    children: [
        {
            id: 'bug-report',
            title: 'Bug report',
            type: 'item',
            url: BUG_REPORT_URL,
            icon: BugReportIcon,
            breadcrumbs: false,
            target: '_blank',
            permission_name: 'selects',
        },
        {
            id: 'feature-request',
            title: 'Feature request',
            type: 'item',
            url: FEATURE_REQUEST_URL,
            icon: ContactSupportIcon,
            breadcrumbs: false,
            target: '_blank',
            permission_name: 'selects',
        },
    ],
};

export default support;
